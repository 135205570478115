import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Layout from "../components/layout"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import ReactPlayer from "react-player"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import SimpleCard from "../components/simpleCard"

const useStyles = makeStyles(theme => ({
  root: {},
  banner: {
    overflow: "visible",
    marginTop: "-140px",
    paddingTop: "100px",
    backgroundColor: "#0a2540",
    transform: "skew(0deg, -6deg)",
  },
  bannerContent: {
    paddingTop: "150px",
    transform: "skew(0deg, 6deg)",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "80px",
    },
  },
  squadre: {
    paddingTop: "65px",
    paddingBottom: "45px",
  },
  tabsMenu: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}))

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "#635ee7",
    },
  },
})(props => (
  <Tabs
    orientation="vertical"
    variant="scrollable"
    {...props}
    TabIndicatorProps={{ children: <span /> }}
  />
))

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: "none",
    color: "#000",
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    fontFamily: "Montserrat",
    fontWeight: "800",
    transition: theme.transitions.create(
      ["background-color", "color", "opacity"],
      { duration: "500ms" }
    ),
    "&:focus": {
      opacity: 1,
      color: "#fff",
      backgroundColor: "#305aed",
    },
    "&:hover": {
      border: "1px solid #305aed",
    },
  },
}))(props => <Tab disableRipple {...props} />)

const Squadre = ({ data }) => {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <Layout title="Squadre">
      <div className={classes.root}>
        <section className={classes.banner}>
          <Container className={classes.bannerContent}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <h1
                  style={{
                    color: "#fe5f55",
                    fontFamily: "Montserrat",
                    fontDisplay: "swap",
                  }}
                >
                  58 anni di Squadre
                </h1>
                <p
                  style={{
                    color: "#fff",
                    fontFamily: "Montserrat",
                    fontDisplay: "swap",
                  }}
                >
                  Scopri le squadre che hanno portato in alto il nome di S.S.
                  Basket San Donà
                </p>
              </Grid>
              <Grid item xs={12} sm={6}>
                <ReactPlayer
                  url="https://player.vimeo.com/video/531284325?autoplay=1&muted=1&background=1&loop=1"
                  width="100%"
                  height="100%"
                  config={{
                    vimeo: {
                      playerOptions: {
                        autoplay: true,
                        loop: true,
                        muted: true,
                        dnt: true,
                        background: true,
                        controls: false,
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className={classes.squadre}>
          <Container>
            <Grid container spacing={5}>
              <Grid item xs={4} sm={4}>
                <h4 style={{ color: "#305aed" }}>Selezioni il periodo</h4>
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  aria-label="Squadre dei periodi"
                  className={classes.tabsMenu}
                >
                  {data.allContentfulPeriodo.nodes.map((periodo, index) => (
                    <StyledTab
                      label={periodo.nome}
                      key={index}
                      {...a11yProps(index)}
                    />
                  ))}
                </StyledTabs>
              </Grid>
              <Grid item xs={8} sm={8}>
                {data.allContentfulPeriodo.nodes.map((periodo, index) => (
                  <TabPanel value={value} index={index} key={index}>
                    <Grid item container key={index}>
                      <Grid item>
                        <h2 style={{ color: "#fe5f55" }}>
                          Squadre del periodo {periodo.nome}
                        </h2>
                      </Grid>
                      <Grid item container spacing={5}>
                        {periodo.anni.map(anno =>
                          anno.squadre.map((squadra, indexSquadra) => (
                            <Grid item xs={12} sm={6} lg={4} key={indexSquadra}>
                              <SimpleCard
                                type="squadre"
                                title={squadra.nome}
                                slug={"/squadre/" + squadra.slug}
                                image={squadra.foto}
                                key={indexSquadra}
                              />
                            </Grid>
                          ))
                        )}
                      </Grid>
                    </Grid>
                  </TabPanel>
                ))}
              </Grid>
            </Grid>
          </Container>
        </section>
      </div>
    </Layout>
  )
}
export default Squadre

export const query = graphql`
  {
    allContentfulPeriodo(
      filter: { node_locale: { eq: "it-IT" } }
      sort: { fields: nome, order: ASC }
    ) {
      nodes {
        nome
        anni {
          squadre {
            nome
            slug
            foto {
              title
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  }
`
